<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="['16px', '24px']"
  >
    <c-form-label
      :font-size="['14px', '16px']"
      :color="value ? '#008C81' : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
    </c-form-label>
    <c-select
      v-model="modelValue"
      :height="['48px', '62px']"
      :font-size="['14px', '18px']"
      :placeholder="placeholder ? placeholder : label"
      :color="value ? '#008C81' : '#555'"
      :font-weight="value ? '500' : '400'"
    >
      <option
        v-if="options.length === 0 && modelValue"
        :key="modelValue"
        :value="modelValue"
      >
        {{ modelValue }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </c-select>
    <c-form-control
      v-if="isOther && (modelValue == otherOption)"
      :is-invalid="otherValue ? false : true"
      :mt="['12px', '16px']"
    >
      <c-input
        v-model="otherValue"
        type="text"
        :height="['48px', '62px']"
        :font-size="['14px', '18px']"
        :color="otherValue ? '#008C81' : '#555'"
        :font-weight="otherValue ? '500' : '400'"
        placeholder="Lainnnya"
        mb="8px"
      />
    </c-form-control>
  </c-form-control>
</template>

<script>

export default {
  name: 'FormSelect',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isOther: {
      type: Boolean,
      default: false,
    },
    other: {
      type: [String, null],
      default: null,
    },
    otherOption: {
      type: [String, null],
      default: null,
    },
  },
  computed: {
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
      },
    },
    otherValue: {
      get() {
        return this.other
      },
      set(val) {
        this.$emit('updateOther', val)
      },
    },
  },
  methods: {
    isIncludesValue(value) {
      return this.options.some((it) => it.value == value)
    },
  }, 
}
</script>
