var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": ['16px', '24px']
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-select', {
    attrs: {
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label,
      "color": _vm.value ? '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400'
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, [_vm.options.length === 0 && _vm.modelValue ? _c('option', {
    key: _vm.modelValue,
    domProps: {
      "value": _vm.modelValue
    }
  }, [_vm._v(" " + _vm._s(_vm.modelValue) + " ")]) : _vm._e(), _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  })], 2), _vm.isOther && _vm.modelValue == _vm.otherOption ? _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.otherValue ? false : true,
      "mt": ['12px', '16px']
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.otherValue ? '#008C81' : '#555',
      "font-weight": _vm.otherValue ? '500' : '400',
      "placeholder": "Lainnnya",
      "mb": "8px"
    },
    model: {
      value: _vm.otherValue,
      callback: function callback($$v) {
        _vm.otherValue = $$v;
      },
      expression: "otherValue"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }