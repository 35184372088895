// Questionnaire step 1
// questionId = 13, question = Apa tujuan utama kamu?
export const OPTIONS_MAIN_TARGET = [
  {
    value: '7',
    label: 'Mencapai hidup sehat jangka panjang',
  },
  {
    value: '8',
    label: 'Mencapai BodyGoals',
  },
]

// questionId = 14, question = Tipikal diet yang kamu harapkan
export const OPTIONS_EXPECTED_DIET = [
  {
    value: '9',
    label: 'Dimulai dari perubahan bertahap dulu',
  },
  {
    value: '10',
    label: 'Langsung diawasi dengan ketat',
  },
]

// questionId = 15, question = Apakah kamu memiliki timbangan berat badan di rumah?
export const OPTIONS_BOOLEAN = [
  {
    value: '11',
    label: 'Ya',
  },
  {
    value: '12',
    label: 'Tidak',
  },
]

// questionId = 16, question = Mana yang paling mendeskripsikan kondisi Klien saat ini?
export const OPTIONS_CURRENT_CONDITION = [
  {
    value: '13',
    label:
      'Tidak tahu kondisi gizi dan kesehatannya, belum tertarik untuk tahu karena merasa masih nyaman dengan kondisi saat ini',
  },
  {
    value: '14',
    label: 'Tidak tahu kondisi gizi dan kesehatannya, tertarik untuk tahu karena ingin berubah',
  },
  {
    value: '15',
    label: 'Tahu kondisi gizi dan kesehatannya, belum tertarik untuk berubah',
  },
  {
    value: '16',
    label:
      'Tahu kondisi gizi dan kesehatannya, sudah tertarik untuk melakukan perubahan karena paham manfaat jangka panjangnya',
  },
  {
    value: '17',
    label: 'Sudah pernah melakukan perubahan diet dan gaya hidup, tapi tidak bertahan lama',
  },
  {
    value: '18',
    label: 'Sudah berhasil melakukan perubahan diet dan gaya hidup dan ingin melakukannya lagi',
  },
]

// Questionnaire step 3
// questionId = 23, question = Preferensi makanan 
export const FOOD_PREFERENCES = [
  {
    value: '19',
    label: 'Tidak ada batasan',
  },
  {
    value: '20',
    label: 'Vegan (sama sekali tidak konsumsi sumber hewani dan turunannya)',
  },
  {
    value: '21',
    label: 'Lacto-Ovo Vegetarian (vegetarian, bisa konsumsi susu dan telur)',
  },
  {
    value: '22',
    label: 'Ovo-Vegetarian (vegetarian, hanya bisa konsumsi telur)',
  },
  {
    value: '23',
    label: 'Hanya bisa makan lauk ikan, seafood, dan lauk nabati lainnya',
  },
]

// questionId = 24, question = Biasanya, berapa kali kamu makan berat atau makan utama dalam hari?
export const MAIN_FOOD_CONSUMPTION = [
  {
    value: '24',
    label: '1 kali/hari',
  },
  {
    value: '25',
    label: '2 kali/hari',
  },
  {
    value: '26',
    label: '3 kali/hari',
  },
  {
    value: '27',
    label: 'Lebih dari 3 kali/hari',
  },
]

// questionId = 25, question = Biasanya, berapa kali kamu makan cemilan dalam hari?
export const SNACK_CONSUMPTION = [
  {
    value: '28',
    label: 'Hampir tidak ada',
  },
  {
    value: '29',
    label: '1 kali/hari',
  },
  {
    value: '30',
    label: '2 kali/hari',
  },
  {
    value: '31',
    label: '3 kali/hari',
  },
  {
    value: '32',
    label: 'Lebih dari 3 kali/hari',
  },
]

// questionId = 33, question = Berapa banyak kamu mengonsumi sayuran dalam sehari?
export const VEGETABLES_CONSUMPTION = [
  {
    value: '33',
    label: 'Tidak pernah',
  },
  {
    value: '34',
    label: 'Kurang dari 3 porsi/hari',
  },
  {
    value: '35',
    label: '3 porsi/hari atau lebih',
  },
]

// questionId = 34, question = Berapa banyak kamu mengonsumi buah dalam sehari?
export const FRUIT_CONSUMPTION = [
  {
    value: '36',
    label: 'Tidak pernah',
  },
  {
    value: '37',
    label: 'Kurang dari 2 porsi/hari ',
  },
  {
    value: '38',
    label: '2 porsi/hari atau lebih',
  },
]

// questionId = 36, question = Berapa banyak kamu mengonsumsi minuman kemasan atau minuman manis dalam sehari?
export const BEVERAGES_CONSUMPTION = [
  {
    value: '39',
    label: 'Tidak pernah',
  },
  {
    value: '40',
    label: 'Kurang dari 1 gelas atau 250 ml / hari ',
  },
  {
    value: '41',
    label: '1 gelas atau 250 ml / hari atau lebih',
  },
]

// questionId = 37, question = Berapa banyak kamu mengonsumsi cemilan gorengan (diluar lauk dalam makan utama) dalam sehari?
export const FRIED_SNACK_CONSUMPTION = [
  {
    value: '42',
    label: 'Tidak pernah',
  },
  {
    value: '43',
    label: 'Kurang dari 3 potong / hari ',
  },
  {
    value: '44',
    label: '3 potong/hari atau lebih',
  },
]

// questionId = 38, question = Berapa sering kamu mengonsumsi makanan ringan asin atau gurih dalam sehari (seperti makanan ringan kemasan, keripik, kacang goreng asin)?
export const SALTY_SNACK_CONSUMPTION = [
  {
    value: '45',
    label: 'Tidak pernah',
  },
  {
    value: '46',
    label: 'Tidak habis 1 bungkus / hari',
  },
  {
    value: '47',
    label: '1 bungkus / hari atau lebih',
  },
]

// questionId = 39, question = Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?
export const SWEET_SNACK_CONSUMPTION = [
  {
    value: '48',
    label: 'Tidak pernah',
  },
  {
    value: '49',
    label: 'Kurang dari 2 potong / hari',
  },
  {
    value: '50',
    label: '2 potong / hari atau lebih',
  },
]

// questionId = 40, question = Berapa sering kamu mengonsumsi cemilan manis dalam sehari (seperti kue-kue manis, brownies, cake, biskuit, coklat, wafer)?
export const PERSON_SERVE_FOOD = [
  {
    value: '51',
    label: 'Saya sendiri',
  },
  {
    value: '52',
    label: 'Anggota keluarga',
  },
  {
    value: '53',
    label: 'Asisten Rumah Tangga',
  },
  {
    value: '54',
    label: 'Beli makanan di luar (beli langsung maupun delivery/pesan antar)',
  },
  {
    value: '55',
    label: 'Dapat makanan di kantor',
  },
]

// questionId = 41, question = Apakah kamu bisa dan terbiasa konsumsi makanan dan minuman yang mengandung susu?
export const MILK_FOOD_AND_DRINK_CONSUMPTION = [
  {
    value: '56',
    label: 'Bisa dan terbiasa',
  },
  {
    value: '57',
    label: 'Bisa tetapi tidak biasa',
  },
  {
    value: '58',
    label: 'Tidak biasa',
  },
]

// Questionnaire step 4
// questionId = 43, question = Jenis olahraga yang dilakukan
export const SPORT_TYPE = [
  {
    value: '60',
    label: 'Tidak ada',
  },
  {
    value: '61',
    label: 'Jalan santai atau naik turun tangga',
  },
  {
    value: '62',
    label: 'Jalan cepat',
  },
  {
    value: '63',
    label: 'Jogging',
  },
  {
    value: '64',
    label:
      'Senam Aerobik / Cardio dan sejenisnya (zumba, poundfit, sepeda, renang, futsal, sepakbola, olahraga permainan lainnya)',
  },
  {
    value: '65',
    label: 'Strength training dan sejenisnya (angkat beban, push up, sit up, squat, dan yang lainnya)',
  },
  {
    value: '66',
    label: 'HIIT dan sejenisnya',
  },
  {
    value: '67',
    label: 'Yoga / Pilates dan sejenisnya',
  },
  {
    value: '68',
    label: 'Lainnya, sebutkan',
    isCustom: true,
  },
]

// questionId = 44, question = Durasi
export const SPORT_DURATION = [
  {
    value: '69',
    label: 'Tidak melakukan atau kurang dari 10 menit',
  },
  {
    value: '70',
    label: '10 - 15 menit',
  },
  {
    value: '71',
    label: '16 - 30 menit',
  },
  {
    value: '72',
    label: '31 - 45 menit',
  },
  {
    value: '73',
    label: '46 - 60 menit',
  },
  {
    value: '74',
    label: 'Lebih dari 60 menit',
  },
]

// questionId = 45, question = Tipikal keaktifan kamu pada hari kerja (weekdays)
export const WEEKDAYS_TYPICAL_ACTIVITY = [
  {
    value: '75',
    label: 'Dominan berbaring',
  },
  {
    value: '76',
    label: 'Dominan duduk',
  },
  {
    value: '77',
    label: 'Banyak berdiri',
  },
  {
    value: '78',
    label: 'Banyak jalan kaki',
  },
]

// questionId = 46, question = Tipikal keaktifan kamu pada hari libur dan akhir pekan
export const WEEKEND_TYPICAL_ACTIVITY = [
  {
    value: '79',
    label: 'Dominan berbaring',
  },
  {
    value: '80',
    label: 'Dominan duduk',
  },
  {
    value: '81',
    label: 'Banyak berdiri',
  },
  {
    value: '82',
    label: 'Banyak jalan kaki',
  },
]

// questionId = 47, question = Apakah kamu pernah didiagnosa dokter untuk mengurangi aktifitas fisik kecuali yang direkomendasikan dokter?
export const LIMITATION_ACTIVITY = [
  {
    value: '83',
    label: 'Pernah',
  },
  {
    value: '84',
    label: 'Tidak Pernah',
  },
]

// questionId = 48, question = Apakah kamu memiliki keterbatasan berikut untuk melakukan aktivitas fisik?
export const PHYSICAL_ACTIVITY = [
  {
    value: '85',
    label: 'Tidak ada',
  },
  {
    value: '134',
    label: 'Gangguan keseimbangan atau pusing sampai pingsan',
  },
  {
    value: '135',
    label: 'Masalah tulang atau persendian',
  },
  {
    value: '136',
    label: 'Tekanan darah tinggi',
  },
  {
    value: '137',
    label: 'Penyakit jantung',
  },
  {
    value: '138',
    label: 'Lainnya',
  },
]

// Questionnaire step 6
// questionId = 51, question = Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?
export const OPTIONS_MENTAL_DISORDER = [
  {
    value: '92',
    label: 'Tidak ada',
  },
  {
    value: '88',
    label: 'Depresi',
  },
  {
    value: '89',
    label: 'Anxiety (Kecemasan berlebih)',
  },
  {
    value: '90',
    label: 'Eating Disorder',
  },
  {
    value: '91',
    label: 'Seasonal Affective Disorder (Gangguan mood musiman)',
  },
  {
    value: '87',
    label: 'Masalah kesehatan mental lainnya',
    isCustom: true,
  },
]

// questionId = 52, question = Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?
export const OPTIONS_DISEASE = [
  {
    value: '139',
    label: 'Tidak ada',
  },
  {
    value: '93',
    label: 'Maag',
  },
  {
    value: '94',
    label: 'Tinggi kolestrol',
  },
  {
    value: '95',
    label: 'Tinggi asam urat',
  },
  {
    value: '96',
    label: 'Anemia',
  },
  {
    value: '97',
    label: 'Diabetes Melitus tipe II (Kencing Manis)',
  },
  {
    value: '98',
    label: 'Diabetes Melitus tipe I (Bawaan/Keturunan)',
  },
  {
    value: '99',
    label: 'Penyakit Jantung',
  },
  {
    value: '100',
    label: 'Penyakit Ginjal Kronis (Belum cuci darah)',
  },
  {
    value: '101',
    label: 'Penyakit Ginjal Kronis (Sudah cuci darah)',
  },
  {
    value: '102',
    label: 'Kanker',
  },
  {
    value: '103',
    label: 'Autoimun',
  },
  {
    value: '104',
    label: 'Lupus',
  },
  {
    value: '105',
    label: 'HIV/AIDS',
  },
  {
    value: '106',
    label: 'Lainnya, sebutkan',
    isCustom: true,
  },
]

// questionId = 53, question = Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?
export const OPTIONS_SYMPTOM = [
  {
    value: '107',
    label: 'Tidak ada',
  },
  {
    value: '108',
    label: 'Mual',
  },
  {
    value: '109',
    label: 'Muntah',
  },
  {
    value: '110',
    label: 'Susah buang air besar dalam 3 hari terakhir',
  },
  {
    value: '111',
    label: 'Kesulitan mengunyah',
  },
  {
    value: '112',
    label: 'Kesulitan menelan',
  },
]

// questionId = 56, question = Apakah kamu dinyatakan dokter memiliki masalah atau gangguan berikut?
export const OPTIONS_CURRENT_BEHAVIOR = [
  {
    value: '113',
    label: 'Saat ini merokok',
  },
  {
    value: '114',
    label: 'Pernah merokok tetapi sudah berhenti',
  },
  {
    value: '115',
    label: 'Terkadang mengonsumsi alkohol',
  },
  {
    value: '116',
    label: 'Tidak termasuk salah satu di atas',
  },
]

// questionId = 59, question = Seperti apa intensitas komunikasi yang kamu inginkan dalam program?
export const OPTIONS_COMMUNICATION_INTENSITY = [
  {
    value: '117',
    label: 'Perhatian dan sering mengingatkan',
  },
  {
    value: '118',
    label: 'Mengingatkan seperlunya saja namun selalu ada mendampingi ketika dibutuhkan',
  },
]

// questionId = 60, question = Pekerjaan
export const OPTIONS_JOBS = [
  {
    value: '119',
    label: 'Pegawai swasta',
  },
  {
    value: '120',
    label: 'PNS',
  },
  {
    value: '121',
    label: 'Wirausaha/Self-employed/Freelancer',
  },
  {
    value: '122',
    label: 'Ibu rumah tangga',
  },
  {
    value: '123',
    label: 'Mahasiswa',
  },
  {
    value: '124',
    label: 'Masih sekolah',
  },
  {
    value: '125',
    label: 'Lainnya',
    isCustom: true,
  },
]

// questionId = 59, question = Pendidikan Terakhir
export const OPTIONS_LAST_EDUCATION = [
  {
    value: '126',
    label: 'S2 atau S3',
  },
  {
    value: '127',
    label: 'S1 atau Diploma',
  },
  {
    value: '128',
    label: 'SMA',
  },
  {
    value: '129',
    label: 'SMP',
  },
  {
    value: '130',
    label: 'SD',
  },
  {
    value: '131',
    label: 'Lainnya',
    isCustom: true,
  },
]
